import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./service.css";
import "./specialmenu.css";

import TxtCourse from '../images/service/txt_course.png'
import Motif_left from '../images/service/motif_left.png'
import Motif_right from '../images/service/motif_right.png'

const SpecialPage = () => (
  <Layout>
    <SEO title="お名前メソッド名前分析鑑定士講座" />
    <div className="service specialmenu">
      <div className="fifth" id="service">
        <ul className="menu">
          <li>
            <div className="detail">
              <p className="ribbon">COURSE</p>
              <div className="head-box">
                <p className="txt-icon">
                  <img alt=" " src={TxtCourse} className="scale-to-fit" />
                </p>
                <p className="en">LESSON COURSE</p>
                <h2 className="special-title ff-min">
                  お名前メソッド
                  <span>&#174;</span><br />
                  <ruby>
                    名前分析鑑定士講座
                    <rp>(</rp><rt>ネームプロファイラー</rt><rp>)</rp>
                  </ruby>
                </h2>
              </div>
              <div className="panel">
                <h4 className="course-title">分析力を磨き、名前を通して「願い」や「夢」理想通りの人生を叶える</h4>
                <ul>
                  <li>&#11045;人生のシナリオを読み解き豊な人を増やしたい</li>
                  <li>&#11045;悩みを解決して自信がつくお手伝いがしたい</li>
                  <li>&#11045;おなまえメソッド<span>&#174;</span>名前分析鑑定士として社会に貢献したい</li>
                </ul>
              </div>
              <div className="panel course">
                <div className="special-head">
                  <p>
                    <img alt=" " src={Motif_left} className="scale-to-fit" />
                  </p>
                  <h3 className="ff-min">講座内容</h3>
                  <p>
                    <img alt=" " src={Motif_right} className="scale-to-fit" />
                  </p>
                </div>
                <ul>
                  <li>&#11045;オンライン受講</li>
                  <li>1日集中コースまたは、3日間ゆったりコースをお選び頂けます。</li>
                  <li>＜所要時間＞全4時間30分</li>
                  <li>＜講座費＞78,000円(税抜) / 公認講師</li>
                </ul>
                <p className="read">
                  おなまえメソッド<span>&#174;</span>スーパーアナグラムによって、名前から個性や才能、人生のシナリオ、未来の可能性を引き出すネームプロファイリングが可能になります。
                </p>
                <ul className="detail">
                  <li>名前から自由自在にメッセージを紐解くリーディングクオリティー維持法や言霊の基礎レッスン</li>
                  <li>ネームプロファイリング術<br />名前を通して心理学・脳科学・行動心理学・言霊、多方面からの分析解読法</li>
                  <li>ネームプロファイラーとしての心得 セルフメンタルケアークリアリング法</li>
                  <li>ビジネスネームや屋号などの改名法の伝授</li>
                  <li>理想のエネルギーを生み出す仕組み</li>
                </ul>
                <p className="read">
                  すぐにビジネスを開始できるように実戦トレーニングも行い、自信をつけていきます！
                </p>
                <div className="btn-holder ff-min">
                  <Link to='/contact' className="special-link">講師を選択する</Link>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default SpecialPage
